var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"min-height":"300px"}},[_c('div',{staticClass:"result-frame"},[(_vm.userInfo.vip)?_c('div',{class:[
        _vm.userInfo.vip.code == 1 ? 'isVip' : 'notVip',
        'user-info-frame'
      ]},[_c('div',{staticClass:"user-info"},[(_vm.userInfo.headimgurl)?_c('img',{staticClass:"img-url",attrs:{"src":_vm.userInfo.headimgurl}}):_vm._e(),(_vm.userInfo.vip && _vm.userInfo.vip.code != 0)?_c('div',{staticClass:"isVip"},[_c('img',{attrs:{"src":require('@/assets/vip-icon.png'),"alt":""}})]):_vm._e(),_c('div',{staticClass:"name-frame"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.userInfo.name))]),_c('div',{staticClass:"vip-logo"})])])]):_vm._e(),_c('div',{staticClass:"user-study-count-frame"},[_c('div',{staticClass:"count-frame"},[_c('div',{staticClass:"count-tips"},[_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.userInfo.join_class))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.joinClass')))])]),_c('div',{staticClass:"count-tips"},[_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.userInfo.exercise_num))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.PracticeTopic')))])]),_c('div',{staticClass:"count-tips"},[_c('div',{staticClass:"count"},[_vm._v(_vm._s(_vm.userInfo.Learning_days))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.learningDays')))])])])]),_c('el-tabs',{staticClass:"count-tabs-frame",on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"name":"ExerciseBookList"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"icon-frame"},[_c('img',{attrs:{"src":_vm.activeName == 'ExerciseBookList'
                  ? require('@/assets/icon/book_pink.svg')
                  : require('@/assets/icon/book_gray.svg'),"alt":""}}),_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.exerciseBook')))])])])]),_c('el-tab-pane',{attrs:{"name":"DictionaryList"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"icon-frame"},[_c('img',{attrs:{"src":_vm.activeName == 'DictionaryList'
                  ? require('@/assets/icon/dictionary_pink.svg')
                  : require('@/assets/icon/dictionary_gray.svg'),"alt":""}}),_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.vocab')))])])])]),_c('el-tab-pane',{attrs:{"name":"ExamMemoryList"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"icon-frame"},[_c('img',{attrs:{"src":_vm.activeName == 'ExamMemoryList'
                  ? require('@/assets/icon/clock_pink.svg')
                  : require('@/assets/icon/clock_gray.svg'),"alt":""}}),_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.examinationRecall')))])])])]),_c('el-tab-pane',{attrs:{"name":"CollectList"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('div',{staticClass:"icon-frame"},[_c('img',{attrs:{"src":_vm.activeName == 'CollectList'
                  ? require('@/assets/icon/star_pink.svg')
                  : require('@/assets/icon/star_gray.svg'),"alt":""}}),_c('span',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('m.collect')))])])])])],1)],1),_c('router-view'),_c('el-dialog',{staticClass:"dialog-renew",attrs:{"visible":_vm.dialogVisibleRenew,"width":"290px"},on:{"update:visible":function($event){_vm.dialogVisibleRenew=$event}}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/vip/popup_icon.png"),"alt":""}})]),_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('placeholder.inputActivationCode')))]),_c('div',{staticClass:"input-frame"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cdkey),expression:"cdkey"}],staticClass:"input-this",attrs:{"type":"text","placeholder":_vm.$t('placeholder.pleaseInput8VipActivationCode')},domProps:{"value":(_vm.cdkey)},on:{"input":function($event){if($event.target.composing)return;_vm.cdkey=$event.target.value}}})]),_c('div',{staticClass:"button-frame"},[_c('button',{attrs:{"type":"button"},on:{"click":_vm.vipactivate}},[_vm._v(" "+_vm._s(_vm.$t('m.activate'))+" ")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.toTaobao}},[_vm._v(_vm._s(_vm.$t('m.goToBuy')))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }