import { apiUserInfo, apiVipactivate } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            loading: true,
            userInfo: {},
            seeStudyConutVisible: false,
            activeName: '',
            dialogVisibleRenew: false,
            cdkey: ''
        }
    },
    // watch:{
    //     activeName(val) {
    //         console.log(val)
    //     }
    // },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.setDefaultActive()
            this.getUserInfo()
        },
        getUserInfo() {
            apiUserInfo().then((res) => {
                this.userInfo = res.data
                this.loading = false
            })
        },
        // 激活
        vipactivate() {
            let paramBase = {
                type:'vip',
                cdkey: this.cdkey
            }
            apiVipactivate(paramBase).then((res) => {
                if (res.code == 200) {
                    this.dialogVisibleRenew = false;
                    this.userInfo.vip = 1;
                    this.$layer.msg(res.msg)
                }
            })
        },
        setDefaultActive() {
            this.activeName = this.$route.name
            this.handleClick()
        },
        handleClick() {
            switch (this.activeName) {
                case 'UserCenter':
                    this.activeName = 'ExerciseBookList'
                    this.$router.push({ name: 'ExerciseBookList' })
                    break;
                case 'ExerciseBookList':
                    this.$router.push({ name: 'ExerciseBookList' })
                    break;
                case 'DictionaryList':
                    this.$router.push({ name: 'DictionaryList' })
                    break;
                case 'ExamMemoryList':
                    this.$router.push({ name: 'ExamMemoryList' })
                    break;
                case 'CollectList':
                    this.$router.push({ name: 'CollectList' })
                    break;
            }
        },
        clickRenew() {
            this.dialogVisibleRenew = true
        },
        toTaobao() {
            window.open('https://item.taobao.com/item.htm?spm=a1z10.5-c-s.w4002-21740808648.10.4fb6620akgBMBl&id=612634020091')
        }
    },
}